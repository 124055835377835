.react-datepicker__input-container {
  width: calc(100% - 10px) !important;
  font-size:15px;
}
.react-datepicker__month-select {
  outline: none;
}
.react-datepicker__year-select{
  outline: none;
}

