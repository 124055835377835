#editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
.ql-editor {
  min-height: 250px;
}
.ql-header {
  width: 150px !important;
 }