:root {
    --PhoneInputCountryFlag-height: 25px;
  }
.phone input[type='tel'] {
    border: 1px solid;
    border-color: #E6E4E3;
    text-transform: none;
    border-radius: 4px;
    padding: 15px 12px
}
.error input[type='tel'] {
    border: 1px solid;
    border-color: #EB5757;
    text-transform: none;
    border-radius: 4px;
    padding: 15px 12px
}
